<!-- Page Preloder -->
<app-header></app-header>
<!-- <div id="preloder">
        <div class="loader"></div>
    </div> -->

<!-- Offcanvas Menu Begin -->
<app-offcanvas-menu></app-offcanvas-menu>
<!-- Offcanvas Menu End -->

<!-- Hero Section Begin -->
<section class="hero">
    <div class="hero__slider owl-carousel">
        <div class="hero__items set-bg" *ngFor="let hero of heros"
            [ngStyle]="{'background-image': 'url(../../../assets/img/hero/' + hero.heroImageUrl + ')'}">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-lg-7 col-md-8">
                        <div class="hero__text">
                            <h6>{{hero.title}}</h6>
                            <h2>{{hero.subtitle}}</h2>
                            <p style="font-size: 115%;"><strong>{{hero.describtion}}</strong></p>
                            <a [routerLink]="['/shop']" class="primary-btn">Zum Shop <span
                                    class="arrow_right"></span></a>
                            <div class="hero__social">
                                <a href="#"><i class="fa fa-facebook"></i></a>
                                <a href="#"><i class="fa fa-twitter"></i></a>
                                <a href="#"><i class="fa fa-pinterest"></i></a>
                                <a href="#"><i class="fa fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Hero Section End -->

<!-- Banner Section Begin -->
<section class="banner spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 offset-lg-4">
                <div class="banner__item">
                    <div class="banner__item__pic">
                        <img class="banner-img" [src]="'../../../assets/img/banner/' + presented[0].imageUrl" alt>
                    </div>
                    <div class="banner__item__text">
                        <h2>{{presented[0].title}}</h2>
                        <a [routerLink]="['/shop']">Shop now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="banner__item banner__item--middle">
                    <div class="banner__item__pic">
                        <img class="banner-img" [src]="'../../../assets/img/banner/' + presented[1].imageUrl" alt>
                    </div>
                    <div class="banner__item__text">
                        <h2>{{presented[1].title}}</h2>
                        <a [routerLink]="['/shop']">Shop now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="banner__item banner__item--last">
                    <div class="banner__item__pic">
                        <img class="banner-img" [src]="'../../../assets/img/banner/' + presented[2].imageUrl" alt>
                    </div>
                    <div class="banner__item__text">
                        <h2>{{presented[2].title}}</h2>
                        <a [routerLink]="['/shop']">Shop now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Banner Section End -->

<app-bestseller></app-bestseller>

<!-- Product Section End -->

<!-- Categories Section Begin -->
<section class="categories spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="categories__text">
                    <h2>{{timerproduct?.category[0]}}<br /> <span>{{timerproduct?.category[1]}}</span> <br />
                        {{timerproduct?.category[2]}}</h2>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="categories__hot__deal">
                    <img class="timeproduct-img" [src]="'../../../assets/img/coins/' + timerproduct.country + '/small_' +  timerproduct.country + '_' + timerproduct.year + '_' + timerproduct.title + '_1.jpg'" alt>
                    <div class="hot__deal__sticker">
                        <span>Rabattpreis</span>
                        <h5>{{timerproduct.vk_price}}€</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 offset-lg-1">
                <div class="categories__deal__countdown">
                    <span>Deal der Woche</span>
                    <h2>{{timerproduct.country}} {{timerproduct.title}}</h2>
                    <h4>{{timerproduct.description}}</h4>
                    <div class="categories__deal__countdown__timer" id="countdown">
                        <div class="cd-item">
                            <span>{{ timeRemaining.days }}</span>
                            <p>Days</p>
                        </div>
                        <div class="cd-item">
                            <span>{{ timeRemaining.hours }}</span>
                            <p>Hours</p>
                        </div>
                        <div class="cd-item">
                            <span>{{ timeRemaining.minutes }}</span>
                            <p>Minutes</p>
                        </div>
                        <div class="cd-item">
                            <span>{{ timeRemaining.seconds }}</span>
                            <p>Seconds</p>
                        </div>
                    </div>
                    <a [routerLink]="['/details', timerproduct.coin_id]" class="primary-btn">Shop now</a>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- Categories Section End -->

<!-- Instagram Section Begin -->
<section class="instagram spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="instagram__pic">
                    <div class="instagram__pic__item set-bg" [ngStyle]="{'background-image': 'url(../../../assets/img/instagram/instagram-1.jpg )','background-size': 'fill',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center' }"></div>
                    <div class="instagram__pic__item set-bg" [ngStyle]="{'background-image': 'url(../../../assets/img/instagram/instagram-2.jpg )','background-size': 'fill',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center' }"></div>
                    <div class="instagram__pic__item set-bg" [ngStyle]="{'background-image': 'url(../../../assets/img/instagram/instagram-3.png )','background-size': 'fill',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center' }"></div>
                    <div class="instagram__pic__item set-bg" [ngStyle]="{'background-image': 'url(../../../assets/img/instagram/instagram-4.png )','background-size': 'fill',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center' }"></div>
                    <div class="instagram__pic__item set-bg" [ngStyle]="{'background-image': 'url(../../../assets/img/instagram/instagram-5.jpg )','background-size': 'fill',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center' }"></div>
                    <div class="instagram__pic__item set-bg" [ngStyle]="{'background-image': 'url(../../../assets/img/instagram/instagram-6.jpg )','background-size': 'fill',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center' }"></div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="instagram__text">
                    <h2>Instagram</h2>
                    <p>Folge uns bei Instagram und werde Teil der Sammlercommunity von Thum. Wir posten dort
                        Informationen zu Neuerscheinungen und Angeboten</p>
                    <h3>#Collectors</h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Instagram Section End -->

<!-- Latest Blog Section Begin -->
<!-- <app-latest-blog></app-latest-blog> -->
<!-- Latest Blog Section End -->
<app-footer></app-footer>