import {
  Component,
  AfterViewInit,
  inject,
  OnInit,
  OnDestroy,
} from '@angular/core';

declare var $: any;
import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'; // Importiere die CSS-Datei

import { HeaderComponent } from '../../header-components/header/header.component';
import { FooterComponent } from '../../footer-components/footer/footer.component';
import { CommonModule } from '@angular/common';
import { OffcanvasMenuComponent } from '../../header-components/offcanvas-menu/offcanvas-menu.component';
import { RouterLink } from '@angular/router';
import { CartService } from '../../../services/cart.service';
import { LatestBlogComponent } from '../../blog-components/latest-blog/latest-blog.component';
import { BestsellerComponent } from '../bestseller/bestseller.component';
import { ProductsService } from '../../../services/products.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    CommonModule,
    OffcanvasMenuComponent,
    RouterLink,
    BestsellerComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements AfterViewInit, OnInit, OnDestroy {
  productImageUrl: string = 'product-1.jpg';
  heroImageUrl: string = 'hero-2.jpg';
  cartService = inject(CartService);
  searchString: string = '';
  timerproduct: any = {
    coin_id: 0,
    title: '',
    vk_price: 0,
    category: [],
    country:'',
    year:'',
    description:'',
    date:''
  };
  constructor(private productSrv: ProductsService) {}
  updateSearchstring(event: Event) {
    const target = event.target as HTMLDivElement;
    this.searchString = target.innerText || ''; // Holt den aktuellen Text des Divs
  }

  heros = [
    // Deine Produkte mit ihren jeweiligen Kategorien
    {
      title: 'Vorverkauf',
      subtitle: 'Die neue Andorra 2024 Münze hier im Vorverkauf',
      describtion:
        'Sichern Sie sich die Gedenkmünze aus 2024 zum Skisport aus Andorra und vervollständigen Sie Ihre Sammlung um ein weiteres Sammlerstück! ',
      heroImageUrl: 'hero-1.png',
    },
    {
      title: 'Summer Collection',
      subtitle: 'Fall - Winter Collections 2030',
      describtion:
        'A specialist label creating luxury essentials. Ethically crafted with an unwavering commitment to exceptional quality.',
      heroImageUrl: 'hero-2.png',
    },
  ];

  presented = [
    { title: 'Andorra 2024 Neuerscheinung', imageUrl: 'banner-1.png' },
    { title: 'Andorra 2024 Kit', imageUrl: 'banner-2.jpg' },
    { title: 'Shoes Spring 2030', imageUrl: 'banner-3.jpg' },
  ];

  countdown: any; // Variable für den Timer
  timeRemaining: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  getOffer() {
    this.productSrv.getTimerProduct().subscribe((res: any) => {
      this.timerproduct = res;
      this.startCountdown(res.date);
    });
  }
  ngOnInit(): void {
    this.getOffer();
  }
  ngOnDestroy(): void {
    clearInterval(this.countdown); // Timer stoppen, wenn das Component zerstört wird
  }
  startCountdown(offerEnd: any) {
    const targetDate = new Date(offerEnd);
    const endTime = targetDate.getTime();
    this.countdown = setInterval(() => {
      const now = new Date().getTime();

      // Berechne die verbleibende Zeit
      const remainingTime = endTime - now;

      if (remainingTime < 0) {
        clearInterval(this.countdown);
        // Timer abgelaufen, hier kannst du Logik hinzufügen
        this.timeRemaining = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        return;
      }

      // Berechnung der verbleibenden Zeit
      this.timeRemaining.days = Math.floor(
        remainingTime / (1000 * 60 * 60 * 24)
      );
      this.timeRemaining.hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.timeRemaining.minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      this.timeRemaining.seconds = Math.floor(
        (remainingTime % (1000 * 60)) / 1000
      );
    }, 1000); // Update jede Sekunde
  }

  ngAfterViewInit(): void {
    $('.hero__slider').owlCarousel({
      items: 1,
      loop: true,
      autoplay: true,
      autoplayTimeout: 7000,
      autoplayHoverPause: true,
    });
  }
  showSearch = false;

  openSearch() {
    this.showSearch = true;
  }

  closeSearch() {
    this.showSearch = false;
    (document.getElementById('search-input') as HTMLInputElement).value = '';
  }
}
