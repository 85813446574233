<!-- Product Section Begin -->
<section class="product spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <ul class="filter__controls">
                    <li [class.active]="activeFilter === 'all'" (click)="filterProducts('all')">Best Seller</li>
                    <li [class.active]="activeFilter === 'new-arrivals'" (click)="filterProducts('new-arrivals')">Neu
                    </li>
                    <li [class.active]="activeFilter === 'hot-sales'" (click)="filterProducts('hot-sales')">Sales</li>
                </ul>
            </div>
        </div>
        <div class="row product__filter">
            <app-popup *ngIf="showPopup" [message]="'Zum Hinzufügen von Favoriten Cookies müssen Sie akzeptiern'" (closePopup)="closePopup()"></app-popup>

            <div *ngFor="let product of filteredProducts"
                class="col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix {{ product.category }}">
                <div class="product__item">
                    <div class="product__item__pic" [routerLink]="['/details', product.coin_id]" [ngStyle]="{'background-image': 'url(../../../assets/img/coins/' + product.country + '/small_' +  product.country + '_' + product.year + '_' + product.title + '_1.jpg)','background-size': 'contain',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center' }">
                        <div *ngIf="product.stock < 5" class="low-stock-banner">
                            Nur noch wenige verfügbar
                        </div>
                        <ul class="product__hover">
                            <li><a (click)="addToFavorite(product); $event.stopPropagation()"><img
                                        src="../../../assets/img/icon/heart.png" alt></a></li>
                            <!-- <li><a href="#"><img src="../../../assets/img/icon/compare.png" alt>
                                    <span>Compare</span></a></li> -->
                            <li><a [routerLink]="['/details', product.coin_id]" (click)="$event.stopPropagation()"><img
                                        src="../../../assets/img/icon/search.png" alt></a></li>
                        </ul>
                    </div>
                    <div class="product__item__text">
                        <h6>{{ product.year }} {{ product.country }} {{ product.title }}</h6>
                        <a (click)="cartService.addToCart(product)" class="add-cart">+ Add To Cart</a>
                        @if(product.discount>0){
                        <h5 id="sales_price">{{product.discount}}
                            €<span>{{product.vk_price}}€</span></h5>}
                        @else { <h5>{{product.vk_price}} €</h5>}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>