// token-interceptor.service.ts
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { LoginService } from './login.service';
import { throwError } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: LoginService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getAccessToken();

    return from(this.handleRequestWithToken(req, accessToken, next));
  }

  private handleRequestWithToken(req: HttpRequest<any>, token: string | null, next: HttpHandler): Observable<HttpEvent<any>> {
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req).pipe(
      catchError(error => {
        if (error.status === 401 && token) { // If unauthorized, try to refresh token
          return this.authService.refreshToken().pipe(
            switchMap(() => this.handleRequestWithToken(req, this.authService.getAccessToken(), next))
          );
        }
        return throwError(error);
      })
    );
  }
}
