import { Routes } from '@angular/router';
import { HomeComponent } from './components/home-components/home/home.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    loadComponent: () =>
      import('./components/header-components/about/about.component').then(
        (mod) => mod.AboutComponent
      ),
  },
  {
    path: 'policies',
    loadChildren: () =>
      import('./components/footer-components/policys/policy-routes').then((mod) => mod.POLICY_ROUTES),
  },
  {
    path: 'contact',
    loadComponent: () =>
      import('./components/footer-components/contacts/contacts.component').then(
        (mod) => mod.ContactsComponent
      ),
  },
  {
    path: 'success',
    loadComponent: () =>
      import('./components/success/success.component').then(
        (mod) => mod.SuccessComponent
      ),
  },
  {
    path: 'shop',
    loadChildren: () =>
      import('./components/shop/routes').then((mod) => mod.SHOP_ROUTES),
  },
  {
    path: 'details/:id',
    loadComponent: () =>
      import('./components/product/product.component').then(
        (mod) => mod.ProductComponent
      ),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./components/blog-components/blog/routes').then((mod) => mod.BLOG_ROUTES),
  },
  {
    path: 'favorites',
    loadComponent: () =>
      import('./components/header-components/favorite/favorite.component').then(
        (mod) => mod.FavoriteComponent
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./components/not-found/not-found.component').then(
        (mod) => mod.NotFoundComponent
      ),
  },
  
];
